html {
  height: 100%;
  overflow: hidden;
}

body {
  min-height: 100%;
}

#root {
  height: 100vh;
  /* background-color: #f6f6ef; */
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: url("./assets/fonts/Poppins-Light.ttf") format("opentype");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: url("./assets/fonts/Poppins-Medium.ttf") format("opentype");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("opentype");
}

@font-face {
  font-family: Poppins;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: url("./assets/fonts/Poppins-Bold.ttf") format("opentype");
}

* {
  font-family: "Poppins", sans-serif !important;
}
